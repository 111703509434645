export const formCheckboxes = () => {
    const formCheckboxes = document.querySelectorAll('.form-checkbox');

    if (formCheckboxes.length > 0) {
        formCheckboxes.forEach(item => {
            const input = item.querySelector("input[type='checkbox']");
            if (!input.classList.contains('-init')) {
                input.addEventListener('keydown', e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();

                        const isChecked = input.checked;
                        input.checked = !isChecked ? true : false;
                    }
                });
                input.classList.add('-init');
            }
        });
    }
};

formCheckboxes();
